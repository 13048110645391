:root {
    --baby-blue: #81defa;
    --primary: #14bae4;
    --primaryRGB: 20, 186, 228;
    --secondary: #0b1f5b;
    --secondaryRGB: 11, 31, 91;
    animation: fadeIn 1s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        scale: 2;
    }

    10% {
        scale: 1;
    }

    100% {
        opacity: 1;
    }
}

/* margin: up right down left */

.App {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    font-size: 2rem;
    min-height: 100vh;
    color: #0b1f5b;
    background-color: rgba(var(--secondaryRGB), 0.01);
}

.headerBar {
    width: calc(100%);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-color: 'red';
    height: 5rem;
}

.headerBarLeft {
    display: flex;
    align-items: center;
    /* cursor: pointer; */
    height: 100%;
    padding-left: 1rem;
}

.headerBarProfile, .headerBarContact {
    display: flex;
    align-items: center;
    /* cursor: pointer; */
    height: 100%;
    padding: 0 1rem;
}

.headerBarContact {
    margin-left: auto;
}

.headerBar p {
    font-weight: 400;
    font-size: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.headerBarLogo {
    transform: translateY(0.3rem);
}

.headerBarLeft > :first-child {
    transform: translateX(3px);
}

.headerBarLeft > :last-child {
    transform: translateX(-4px);
}

.profileTitle {
    font-size: 2rem;
    font-weight: 400;
    margin: 0.5rem 0 0.5rem 0;
    letter-spacing: 0.05rem;
}

.heroText {
    font-weight: 500;
    font-size: 32pt;
    margin: 2rem 1rem 0 1rem;
    line-height: 1.1;
    text-align: center;
}

.heroSubText {
    font-weight: 400;
    font-size: 18pt;
    margin: 1rem 0 0 0;
    text-align: center;
}

.loginRegisterBtn {
    background-color: var(--primary);
    color: white;
    border: none;
    border-radius: 10rem;
    min-width: 10rem;
    padding: 1rem 1.5rem;
    font-size: 1.2rem;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    outline: none;
    /* cursor: pointer; */
}

.btnLoadingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10rem;
}

.btnLoadingText {
    font-size: 1.2rem;
    font-weight: 100;
    color: rgb(0, 107, 0);
    margin-right: 0.5rem;
}

.loginGoogleHome {
    min-width: calc(100%);
}

.loginGoogleSignInUp {
    transform: translateY(-0.5rem);
}

.logoutBtn {
    background-color: rgba(var(--primaryRGB), 0.5);
    /* cursor: pointer; */
}

.green {
    background-color: rgba(0, 216, 0, 0.322);
    color: rgb(0, 107, 0);
    border: 1px solid rgba(0, 107, 0, 0.4);
    box-shadow: inset 0px 1px 1px 0px rgba(0, 107, 0, 0.4);
}

.btnLoading {
    color: transparent;
}

.loadingSpinner {
    width: 1rem;
    height: 1rem;
    border: 0.2rem solid rgba(0, 107, 0, 0.3);
    border-top: 0.2rem solid rgba(0, 107, 0, 0.6);
    border-radius: 50%;
    animation: spin 0.5s linear infinite;
}

.pageLoadingContainer {
    margin-top: 12rem;
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
}

.pageNoOfGalleries {
    font-size: 1.5rem;
    font-weight: 200;
    transform: translateY(-0.5rem);
}

.loadingTxt {
    font-size: 2rem;
    font-weight: 100;
    transform: translateY(-0.2rem);
}

.loadingSpinnerBlue {
    width: 1rem;
    height: 1rem;
    border: 0.2rem solid rgba(var(--secondaryRGB), 0.3);
    border-top: 0.2rem solid rgba(var(--secondaryRGB), 0.6);
    border-radius: 50%;
    animation: spin 0.5s linear infinite;
}

.checkoutShadow {
    transform: translateY(-3px);
    box-shadow: 0px 2px 0px 0px rgba(0, 107, 0, 0.5);
    border-width: 1px;
}

.poweredByStripe {
    font-size: 1rem;
    font-weight: 300;
    color: rgba(0, 0, 0, 1);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
    transform: translateY(-0.7rem);
}

.stripeTxtIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(0.08rem);
}

.warningColor {
    margin-top: 0.5rem;
    background-color: rgb(255, 30, 30);
}

.inactiveColor {
    background-color: rgb(200, 200, 200);
}

.loginRegisterBtnInverted {
    background-color: white;
    font-weight: 500;
    color: var(--primary);
    border: 2px solid var(--primary);
}

.loginWithGoogleBtn {
    border-radius: 10rem;
    padding: 1rem 1.5rem;
    font-size: 1.2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    /* cursor: pointer; */
    background-color: white;
    font-weight: 500;
    color: var(--primary);
    border: 2px solid var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    transform: translateY(-0.5rem);
}

.userInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer {
    margin-top: auto;
    width: 100%;
    background-color: var(--secondary);
    display: flex;
    align-items: flex-start;
    padding: 2rem 0;
    flex-direction: column;
    gap: 0.5rem;
}

.footerText {
    color: white;
    text-align: center;
    width: 100%;
    opacity: 0.7;
    font-size: 1.5rem;
    font-size: 12pt;
    font-weight: 200;
}

.registerTitle {
    font-size: 2rem;    
    font-weight: 500;
    margin: 1rem 0 0.5rem 0;
    text-align: center;
    width: calc(100% - 2rem);
}

.registerSubtitle {
    font-size: 1.1rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
}

.registerSubtitleLink {
    color: var(--primary);
    /* cursor: pointer; */
}

.registerInputLbl {
    font-size: 1.3rem;
    font-weight: 400;
    width: calc(100% - 2rem);
    color: var(--secondary);
    margin: 0.8rem 0 0.2rem 1.5rem;
}

.registerInput {
    font-size: 1.2rem;
    font-weight: 400;
    margin: 0;
    padding: 0.7rem;
    width: calc(100% - 4rem);
    border: 1px solid rgba(var(--secondaryRGB), 0.7);
    border-radius: 0.5rem;
}

.inputErrorLbl {
    all:unset;
    align-self: center;
    color: red;
    font-size: 1rem;
    font-weight: 500;
    margin-top: 0.2rem;
    background-color: rgba(255, 182, 182, 0.603);
    border-radius: 100px;
    overflow: hidden;
    max-height: 0px;
    padding: 0rem;
    transition: max-height 0.5s, padding 0.5s;
}

.successLbl {
    color: rgb(0, 165, 0);
    background: rgba(0, 238, 0, 0.3);
}

.inputErrorLbl.show {
    max-height: 50px;
    padding: 0.1rem 0.7rem 0.2rem 0.7rem;
    transition: max-height 0.5s, padding 0.5s;
}

.registerInput::placeholder {
    color: var(--secondary);
    opacity: 0.5;
}

.registerInputNotEditable {
    background-color: #ececec;
    color: #3a3a3a;
    pointer-events: none;
}

.registerInputWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.profileNameWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.showPasswordIcon {
    position: absolute;
    right: 2rem;
    /* cursor: pointer; */
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
}

.spinning {
    animation: spin 0.3s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loginWithWrapper {
    margin-top: 1rem;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
}

.loginWithIcon {
    border: 1px solid rgba(var(--secondaryRGB), 0.7);
    width: 28%;
    border-radius: 1rem;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profileNameInput {
    border: 0;
    font-size: 1.4rem;
    font-weight: 400;
    margin: 0;
    padding: 0.7rem 0.5rem;
    width: calc(100% - 4rem);
    border-bottom: 1px solid rgba(var(--secondaryRGB), 0.25);
    color: var(--secondary);
    background-color: transparent;
}

.profileNameInput:focus {
    outline: none;
    border-bottom: 1px solid var(--secondary);
}

.profilePic {
    margin-top: 0rem;
    width: 50%;
    overflow: hidden;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 1rem;
}

.changeProfilePicBtn {
    font-weight: 300;
    font-size: 1rem;
    border-bottom: 1px solid rgba(var(--secondaryRGB), 0.7);
    cursor: pointer;
}



.profilePicImage {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    object-fit: cover;
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.5);
    background-color: white;
}

.profilePicImage > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profilePicEdit {
    position: absolute;
    right: 0px;
    bottom: 0px;
    background-color: white;
    border-radius: 100px;
    padding: 0.5rem;
    /* cursor: pointer; */
    background-color: transparent;
}

.myGalleriesHdr {
    margin-top: 0.5rem;
    width: calc(100% - 3rem);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding: 0 0rem;
    border-bottom: 1px solid rgba(var(--secondaryRGB), 0.7);
}

.myGalleryTitle {
    font-size: 1.5rem;
    margin: 1rem 0 0.5rem 0;
}

.addGalleryBtn, .previewPageBtn {
    background-color: white;
    color: var(--secondary);
    border: 1px solid rgba(var(--secondaryRGB), 0.7);
    border-radius: 10rem;
    padding: 0.4rem 1rem;
    font-size: 1rem;
    transform: translateY(0.25rem);
}

.previewPageBtn {
    color: var(--secondaryRGB);
    border: 1px solid var(--secondary);
    border-radius: 1rem;
    padding: 0.7rem 0;
    margin: 0.5rem 0;
}

.myGalleriesBody {
    width: calc(100% - 3rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.myGalleryCard {
    width: 90%;
    display: flex;
    align-items: center;
    flex-direction: column;
    /* border: 1px solid rgba(var(--secondaryRGB), 0.2); */
    border-radius: 2rem;
    box-shadow: 0px 2px 7px 0px rgba(var(--secondaryRGB), 0.5);
    padding-bottom: 1rem;
}

.myGalleryCardImage {
    width: 70%;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.5);
    margin: 1.5rem 0 0 0;
}

.myGalleryCardImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.myGalleryCardName {
    all: unset;
    font-size: 1.7rem;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    /* white-space: nowrap; */
    max-width: 100%;
    text-align: center;
    margin: 0.5rem 0.5rem;
}

.myGalleryCardPrice {
    font-size: 1.5rem;
    font-weight: 400;
    color: rgba(var(--secondaryRGB), 0.9);
    margin: 0.5rem 0 0 0;
}

.scrollSpacer {
    height: 1rem;
}

.scrollSpacerSmall {
    height: 0.5rem;
}

.createGalleryTitle {
    margin: 1rem 0 0 0;
}

.editGalleryTitle {
    margin: 1rem 0 0.5rem 0;
}

.createGallerySubTitle {
    all: unset;
    font-size: 1rem;
    margin-bottom: 1rem;
}

.myGalleryCardStatusPublished {
    border: 1px solid green;
    background-color: rgba(0, 255, 0, 0.05);
    color: green;
    border-radius: 0.7rem;
    padding: 0.2rem 0.5rem;
    font-size: 1rem;
    margin: 0 0 0.3rem 0;
}

.myGalleryCardStatusPrivate {
    border: 1px solid rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.7);
    border-radius: 0.7rem;
    padding: 0.2rem 0.5rem;
    font-size: 1rem;
    margin: 0 0 0.3rem 0;
}

.statusOptions {
    font-size: 1.2rem;
    font-weight: 400;
    margin: 0 0 0rem 0;
    width: calc(100% - 2.6rem);
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.createGalleryInfoLbl{
    font-size: 1rem;
    font-weight: 200; 
    line-height: 1.2;
    text-align: center;
    width: calc(100% - 3rem);
}

.statusOptionPrivate, .statusOptionPublished {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0;
    margin: 0.5rem 0;
    border-radius: 1rem;
    /* cursor: pointer; */
    transition: background-color 0.3s;
    font-size: 1.2rem;
    font-weight: 200;
}

.statusOptionPrivate {
    background-color: rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.5);
}

.statusOptionPublished {
    background-color: rgba(0, 255, 0, 0.05);
    border: 1px solid rgba(0, 128, 0, 0.3);
    color: rgba(0, 128, 0, 0.5);
}

.statusOptionPrivate.selected {
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.4);
    color: rgb(0, 0, 0, 0.7);
    font-weight: 400;
}

.statusOptionPublished.selected {
    background-color: rgba(0, 255, 0, 0.1);
    border: 1px solid rgba(0, 128, 0, 0.7);
    color: rgb(0, 128, 0);
    font-weight: 400;
}

.marginTopAuto {
    margin-top: auto;
}

.galleryImagesContainer {
    width: calc(100%);
    height: 10rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    flex-wrap: nowrap;
    overflow-x: auto;
}

@keyframes hintScroll {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(0.5rem);
    }

    100% {
        transform: translateX(0);
    }
}

.justifyCenter {
    justify-content: center;
}

.verticalSpacer {
    min-width: 0.5rem;
}

.galleryImage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8rem;
    min-width: 8rem;
    max-width: 8rem;
    aspect-ratio: 1;
    border: 1px solid var(--secondary);
    border-radius: 15%;
    /* cursor: pointer; */
    position: relative;
}

.galleryImage img {
    border-radius: 15%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.galleryImageRemoveIcon {
    position: absolute;
    right: -0.7rem;
    top: -1rem;
    /* cursor: pointer; */
    z-index: 1;
}

.pageBio {
    font-size: 1.2rem;
    font-weight: 200;
    margin: 0.5rem 0.5rem;
    text-align: center;
}

.pageDivider {
    width: 50%;
    height: 1px;
    background-color: rgba(var(--secondaryRGB), 0.2);
    margin: 1rem 0;
}

.galleryDivider {
    width: 90%;
    height: 1px;
    background-color: rgba(var(--secondaryRGB), 0.2);
    margin: 1rem 0;
}

.contactForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
}

.contactInputMessage {
    height: 10rem;
    margin-top: 0.5rem;
}

.myGalleryName {
    all: unset;
    font-size: 1.5rem;
    font-weight: 500;
    margin: 1rem 0 0rem 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
}

.myGalleryImgCount {
    font-size: 1.3rem;
    font-weight: 100;
    margin-bottom: 0.5rem;
}

.checkoutCompletedContainer {
    all: unset;
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.orderHistoryContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.orderHistoryItem {
    width: calc(90% - 2rem);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0.5rem;
    border: 1px solid rgba(var(--secondaryRGB), 0.2);
    border-radius: 1rem;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
    position: relative;
}

.orderHistoryItemDots {
    position: absolute;
    right: 0.8rem;
    top: 0.4rem;
    transform: rotate(45deg);
}

.orderHistoryItemRow1 {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
}

.orderHistoryItemRow2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.orderHistoryImage {
    width: 5rem;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    object-fit: cover;
    border-radius: 1.5rem;
    box-shadow: -2px 2px 7px 0px rgba(0, 0, 0, 0.5);
}

.orderHistoryItemTitle {
    font-size: 1.1rem;
    font-weight: 500;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
}

.orderHistoryItemPublisher, .orderHistoryItemPrice {
    font-size: 1rem;
    font-weight: 200;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
}

.orderHistoryItemPrice {
    margin: 0.5rem 0;
}

.orderHistoryItemTime {
    font-size: 1rem;
    font-weight: 200;
    margin: 0;
    padding: 0.8rem 0 0 0;
}

.orderHistoryItemBtn {    
    background-color: white;
    color: rgba(var(--primaryRGB), 0.7);
    border-radius: 10rem;
    padding: 0.4rem 1rem;
    font-size: 1rem;
    /* cursor: pointer; */
    border: 1px solid var(--primary);
}

.orderHistoryItemBtnPending {
    background-color: white;
    color: rgba(var(--secondaryRGB), 0.5);
    border-radius: 10rem;
    padding: 0.4rem 1rem;
    font-size: 1rem;
    border: 1px solid rgba(var(--secondaryRGB), 0.5);
}

.orderHistoryItemBtnDownloaded {
    background-color: white;
    color: rgba(var(--secondaryRGB), 1);
    border-radius: 10rem;
    padding: 0.4rem 1rem;
    font-size: 1rem;
    border: 1px solid rgba(var(--secondaryRGB), 1);
}

.profileInputLbl {
    font-size: 1rem;
    margin-bottom: 0;    
    font-weight: 200;
}

.inputLblFocus {
    font-weight: 400;
}

.paymentSuccessIcon {
    box-shadow: 0px 0px 15px 10px rgba(41, 202, 157, 0.5);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: grow 0.5s;
}

@keyframes grow {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

.checkoutCompletedTitle {
    font-size: 2rem;
    margin: 0;
    width: 90%;
    text-align: center;
}

.checkoutCompletedTxt {
    font-size: 1.2rem;
    font-weight: 200;
    margin: 0;
    width: 90%;
    text-align: center;
}

.purchaseInfo {
    font-size: 1rem;
    font-weight: 200;
    margin: 0;
    width: 90%;
    text-align: center;
    line-height: 1.5;
    letter-spacing: 0.02rem;
}

.purchaseInfoTos {
    font-size: 0.9rem;
    font-weight: 200;
    margin: 0;
    width: 75%;
    text-align: center;
    line-height: 1.5;
    letter-spacing: 0.02rem;
}

.purchaseInfoTosClick {
    text-decoration: underline;
    color: var(--secondary);
    cursor: pointer;
}

.termsOfServiceContainer {
    font-size: 1rem;
    font-weight: 200;
    margin: 0 1rem;
}